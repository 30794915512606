.LoginoutPage .lander {
	padding: 80px 0;
	text-align: center;
}

.LoginoutPage .lander h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.LoginoutPage .lander p {
	color: #999;
}

.SignupPage .lander {
	padding: 80px 0;
	text-align: center;
}

.SignupPage .lander h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.SignupPage .lander p {
	color: #999;
}

.SignupPage form {
	max-width: 450px;
	text-align: center;
	margin: 0 auto;
	border: 1px solid gray;
	border-radius: 4px;
	padding: 50px;
}


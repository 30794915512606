@media all and (min-width: 480px) {
	.LoginForm {
		padding: 60px 0;
	}

	.LoginForm form {
		margin: 0 auto;
		max-width: 450px;
		border: 1px solid gray;
		border-radius: 4px;
		padding: 50px;
	}
}
